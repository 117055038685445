// Here you can add other styles
.essential {
  color: red;
}
.was-validated {
  .date-picker.validity-invalid {
    .form-control:valid {
      border-color: $form-feedback-invalid-color;
      background-image: escape-svg($form-feedback-icon-invalid);
    }
    .input-group-text {
      border-color: $form-feedback-invalid-color;
    }
  }

  .form-select:valid:not([multiple]):not([size]) {
    background-image: none;
  }
}

html:not([dir='rtl']) {
  .was-validated {
    .form-select:valid:not([multiple]):not([size]) {
      padding-right: 0.25rem;
    }
  }
}

.essential {
  color: $danger;
}

.was-validated {
  .date-picker.validity-invalid {
    .form-control:valid {
      background-image: escape-svg($form-feedback-icon-invalid);
      border-color: $form-feedback-invalid-color;
    }
    .input-group-text {
      border-color: $form-feedback-invalid-color;
    }
  }

  .form-select:valid:not([multiple]):not([size]) {
    background-image: none;
  }
}

html:not([dir='rtl']) {
  .was-validated {
    .form-select:valid:not([multiple]):not([size]) {
      padding-right: 0.25rem;
    }
  }
}
